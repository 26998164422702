import { getFullAddress } from "@/utils/string";

class FloorPlanVideoModel {
    static postPayload(e) {
        return {
            title: e.title || "",
            videoLink: getFullAddress(e.videoLink) || "",
            thumbnail: e.thumbnail || [],
            projectUnitFloorPlanId: e.projectUnitFloorPlanId || ""
        };
    }

    static getToEditResponse(e) {
        return {
            id: e.id || "",
            title: e.title || "",
            videoLink: e.videoLink || "",
            thumbnail: e.thumbnail || [],
            projectUnitFloorPlanId: e.projectUnitFloorPlan.id || ""
        };
    }
}

export default FloorPlanVideoModel;
