<template>
  <div class="fp-videos">
    <div class="card row p-2 mb-1">
      <h4 class="mb-3">Videos</h4>

      <!-- Loader -->
      <div
        v-if="isLoading"
        class="
          col-12
          spinner-container
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <spinner></spinner>
      </div>

      <!-- If already has video -->
      <div v-else-if="myVideo.length > 0" class="col-12">
        <video-card :video="myVideo[0]" showStatus></video-card>
      </div>

      <!-- Video Input -->
      <fd-form v-else class="col-12" @submit="submitForm">
        <fd-input
          v-model="videoData.title"
          class="col-12 mb-2"
          label="Title"
          name="fpVideoTitle"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <fd-input
          v-model="videoData.videoLink"
          class="col-12 mb-2"
          label="Video Link"
          name="fpVideoTitle"
          type="text"
          :validators="[validator.required, validator.website]"
        >
        </fd-input>
        <video-embed
          css="embed-responsive-16by9"
          :src="videoData.videoLink"
        ></video-embed>

        <div class="d-flex justify-content-end mt-2">
          <button class="btn main">Post Video</button>
        </div>
      </fd-form>
      <!-- Comments display -->
      <!-- <div class="col-12">
        <video-card v-for="n in 4" :key="n" class="mb-3"></video-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  required,
  website
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import FloorPlanVideoModel from "@/models/floorPlanVideo";

export default {
  components: {
    VideoCard: () => import("@/components/ManageFloorPlanVideos/VideoCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    floorPlanId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,

      myVideo: [],

      videoData: {
        title: "",
        videoLink: "",
        thumbnail: [],
        projectUnitFloorPlanId: ""
      },

      validator: {
        required: required,
        website: website
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.videoData.projectUnitFloorPlanId = this.floorPlanId;
      await this.getMyVideo();
      this.isLoading = false;
    },
    async getMyVideo() {
      try {
        let response = await this.$store.dispatch(
          "manageFloorPlanVideos/getAllVideos",
          {
            queries: {
              self: true,
              "projectUnitFloorPlan:id": this.floorPlanId
            }
          }
        );
        this.myVideo = this._.cloneDeep(response.data);
      } catch (error) {
        throw error;
      }
    },
    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch(
          "manageFloorPlanVideos/createVideo",
          FloorPlanVideoModel.postPayload(this.videoData)
        );
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Your video has been sucessfully posted. Now pending admin's approval."
        });
        await this.getMyVideo();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "FP Post Video");
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.fp-videos {
  .spinner-container {
    min-height: 300px;
  }
}
</style>
